<template>
  <b-modal id="indice" centered ref="modal" title-class="font-22"
           hide-footer  :title="title" @shown="indice = { ...indice_p}" @hide="indice={}">
    <div class="card">
      <div class="card-body">

        <div class="row mx-0">
                    <audio controls autoplay v-if="indice.type =='audio'||indice.type =='solution'">
                      <source :src="indice.content" type="audio/mpeg">
                      Your browser does not support the audio element.
                    </audio>
          <img v-else-if="indice.type == 'image'" :src="indice.content"/>
          <p v-else class="text-center">{{ indice.content }}</p>

        </div>
      </div>
    </div>

  </b-modal>
</template>

<script>

export default {
  name: "indice",
  props:['title','indice_p'],
data(){
    return{
      indice:{
        type:'text',
        content:""
      }
    }
},


}
</script>

<style scoped>
/deep/ .modal-body {
  padding: 0rem !important;
}

/deep/ .modal-content {
  background-image: linear-gradient(rgba(19,12,16,1),rgba(25,12,12,1));
  background-color: inherit!important;
  border-radius: 20px !important;

}
/deep/.modal-header button{
  cursor: pointer;
  background: transparent!important;
  border: none!important;
  color: white;
  font-size: 40px;
}
/deep/.card{
  background-color: inherit!important;
  border: none!important;
}
/deep/.modal-title{
  margin-left: auto;
  margin-right: auto;
}
.btn-save {
  border-radius: 0 0 20px 20px!important;
  position: relative;
  bottom: 0;
  font-size: 20px!important;
  background-color: rgba(20, 5, 5, 1)!important;
  color: #FFFFFF!important;
}

.active {
  text-align: center;
  background: #fff;

}

h6 {
  padding: 40px 0;
}
</style>