<template>

  <section class="container">
    <div class="row mx-0">
    <h2 class="text-center my-5">{{$t('senarios.title')}}</h2>
    <div class="col-xl-4 col-md-6 mb-4" v-for="item in senarios" v-bind:key="item.id">
  <div class="card  mx-auto mb-2">
    <div class="box text-center" role="button" @click="ShowJoin(item)">
      <img :src="item.image" height="100%" width="100%"/>
      <div class="content">
        <h3>{{item.title}}</h3>
        <!-- <p>{{item.description}}</p> -->
      </div>
    </div>
    <div class='banner' v-if="item.is_available == 0">{{$t('senarios.soon')}}</div>
  </div>
</div>

</div>

    <join-scenario :id="selected_id" :lang="lang"/>
  </section>

</template>

<script>
import JoinScenario from "@/components/JoinScenario";
export default {
  name: "Senarios",
  components: {JoinScenario},
  props:['lang'],
  data(){
    return{
      senarios:[ ],
      selected_id:'',
    }
  },
  watch:{
    lang: function () {
      this.getSenarios()
      this.$forceUpdate();
    },
  },
  created(){
      this.getSenarios(1)

  },
  methods:{
    ShowJoin(item){
      if(item.is_available){
      this.selected_id = item.senario_id
      this.$root.$emit('bv::show::modal', 'join')
      }
    },
    getSenarios(){
      this.$http
          .get('senarios/'+this.lang)
          .then(response => {

              this.senarios = response.data

          })
          .catch(error => {
            console.log(error)
          })
    }

  }
}
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Rye);

.active{
  cursor: pointer;
}
.disabled {
  pointer-events: none;
}
.banner {
  position: absolute;
  display: block;
  margin: 100px 0;
  width: 100%;
  height: 60px;
  border: 1px solid #fff;
  font: normal 30px/60px 'Rye';
  text-align: center;
  color: #451;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 30px rgba(0,0,0,.15) inset,
  0 6px 10px rgba(0,0,0,.15);
}
.btn{
  font: normal 18px 'Rye';
  color: #451;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 30px rgba(0,0,0,.15) inset,
  0 6px 10px rgba(0,0,0,.15);
}

.banner::before,
.banner::after {
  content: '';
  position: absolute;
  z-index: -1;
  left: -70px;
  top: 24px;
  display: block;
  width: 40px;
  height: 0px;
  border-bottom-color: #fff;
  border-left-color: transparent;
  transform: rotate(-5deg);
}

.banner::after {
  left: auto;
  right: -70px;
  border-left: 20px solid #fff;
  border-right: 30px solid transparent;
  transform: rotate(5deg);
}

.card {
  position: relative;
  height: 400px;
  width: 90%;
  background: #2a2b2f;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
  inset -5px -5px 15px rgba(255, 255, 255, 0.1),
  5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  transition: 0.5s;
}

.box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.box:hover {
  transform: translateY(-50px);
}

.box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

.box .content {
  padding: 20px;
  position: absolute;
  top: 5rem;

  width: 100%;
  text-align: center;

}



.box .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.col-xl-4{
  margin-left: auto;
  margin-right: auto;
}

</style>