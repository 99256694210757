<template>
  <section class="row mx-0 mb-4 ">
      <h1 class="text-center my-5">{{$t('regles.title')}}</h1>
      <div class="col-xl-10 mx-auto ">
        <div class="card-body justify-content-center pt-3" v-html="rules">
          {{rules}}
<!--            <h5 class="my-3"><strong>{{ $t('regles.header')}}</strong></h5>-->
<!--            <ul class="m-4">-->
<!--              <li>{{$t('regles.s')}} 1 : {{$t('regles.e1')}}</li>-->
<!--              <li>{{$t('regles.s')}} 2 : {{$t('regles.e2')}}</li>-->
<!--              <li>{{$t('regles.s')}} 3 : {{$t('regles.e3')}}</li>-->
<!--              <li>{{$t('regles.s')}} 4 : {{$t('regles.e4')}}</li>-->
<!--              <li>{{$t('regles.s')}} 5 : {{$t('regles.e5')}}</li>-->
<!--              <li>{{$t('regles.s')}} 6 : {{$t('regles.e6')}}</li>-->
<!--            </ul>-->
<!--            <p>{{$t('regles.p1')}}</p>-->
<!--            <p>{{$t('regles.p2')}}</p>-->
<!--            <p>{{$t('regles.p3')}}</p>-->
<!--            <p>{{$t('regles.p4')}}</p>-->
<!--            <p>{{$t('regles.p5')}}</p>-->
          </div>
      </div>
  </section>
</template>

<script>

export default {
  name: "Regles",
  props:['rules'],

}
</script>

<style scoped>
.card {
  background-color: #b13638;
}

h2 {
  text-decoration: underline;
}
li{
  margin-bottom: 10px;
}
</style>