<template>
  <main class="container">
    <div  class="row mx-0">
              <h5 class="col-xl-10 mx-auto text-center mt-4" v-if="form_submitted">{{ $t('stats.msg') }}  </h5>
              <h5 class="col-xl-10 mx-auto text-center my-4" v-else>{{ $t('stats.stat') }}  </h5>

      <form class="col-xl-8 mx-auto" id="form" method="POST" @submit.prevent="handleSubmit" v-if="form_submitted">
        <div class="row mx-0 mb-4">
          <div class="col-xl-5 form-group mx-auto mt-3 mb-0">
            <label class="pb-2">{{ $t('stats.time') }} :</label>
            <div class="row mx-0 ">
              <div class="col-6 pe-0">
                <b-form-input type="number"  min="0" max="60" placeholder="HH" v-model="item.hours"
                              :class="{ 'is-invalid': typesubmit && $v.item.hours.$error }"></b-form-input>
                <div v-if="typesubmit && $v.item.hours.$error" class="invalid-feedback">
                  <span v-if="!$v.item.hours.required"> {{ $t('join.required') }}</span>
                </div>
              </div>
              <div class="col-6 ps-0">
                <b-form-input type="number" min="0" max="60" placeholder="min" v-model="item.minutes"
                              :class="{ 'is-invalid': typesubmit && $v.item.minutes.$error }"></b-form-input>
                <div v-if="typesubmit && $v.item.minutes.$error" class="invalid-feedback">
                  <span v-if="!$v.item.minutes.required"> {{ $t('join.required') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 form-group mx-auto mt-3 mb-0">
            <label class="pb-2">{{ $t('stats.persons') }} :</label>
            <b-form-input type="number" min="0" v-model="item.persons"
                          :class="{ 'is-invalid': typesubmit && $v.item.persons.$error }"></b-form-input>
            <div v-if="typesubmit && $v.item.persons.$error" class="invalid-feedback">
              <span v-if="!$v.item.persons.required"> {{ $t('join.required') }}</span>
            </div>
          </div>
          <div class="col-xl-2 form-group mx-auto mt-3 mb-0 d-flex align-items-end">
            <input type="submit" class="btn  px-3  mx-auto" value="Save"/>
          </div>
        </div>
      </form>
    </div>
    <div class="row mx-0 align-items-center" v-if="times.series.length && pers.series.length">
      <div class="col-xl-6" >
        <apexchart 
                   class="apex-charts"
                   height="300"
                   type="pie"
                   dir="ltr"
                   :series="times.series"
                   :options="times.chartOptions"
        ></apexchart>
      </div>
      <div class="col-xl-6">
        <apexchart 
                   class="apex-charts"
                   height="300"
                   type="pie"
                   dir="ltr"
                   :series="pers.series"
                   :options="pers.chartOptions"
        ></apexchart>
      </div>

    </div>

  </main>

</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  name: "SetTime",
  data() {
    return {
      typesubmit: false,
      form_submitted: true,
      times : {
        series: [],
        chartOptions: {
          labels: [],
          title:{
            text:'Time'
          },
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '12px',
            offsetY: 0
          },
          responsive: [{
            breakpoint: 600,
            options: {
              chart: {
                height: 240
              },
              legend: {
                show: false
              },
            }
          }]
        }
      },
      pers : {
        series: [],
        chartOptions: {
          labels: [],
          title:{
            text:'Personnes'
          },
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '12px',
            offsetY: 0
          },
          responsive: [{
            breakpoint: 600,
            options: {
              chart: {
                height: 240
              },
              legend: {
                show: false
              },
            }
          }]
        }
      },
      item: {
        hours: '',
        minutes: '',
        persons: ''
      }
    }
  },
  validations: {
    item: {
      hours: {required},
      minutes: {required},
      persons: {required},
    }
  },
  methods: {
    handleSubmit() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.setTime();
    },

    setTime() {
      this.$http
          .post('stats', this.item)
          .then(response => {
            console.log(response.data)
            let data  = response.data.times
            this.times.series  = Object.values(data)
            this.times.chartOptions = {...data.chartOptions, ...{
                labels:Object.keys(data),
                 title:{
                    text:'Niveau'
                  },
              }}
            let data1  = response.data.pers
            this.pers.series  = Object.values(data1)
            this.pers.chartOptions = {...data1.chartOptions, ...{
                labels:Object.keys(data1),
                  title:{
                    text:'Nbr de personnes'
                  },
              }}
            this.$alertify.success("Success")
            this.form_submitted = false

          })
          .catch(error => {
            console.log(error.response.data)
            this.$alertify.error(error.response.data.error)
          })

    }
  }
}
</script>

<style scoped>

.btn {
  background-color: #c9211e;
  color: white;

}
/deep/.apexcharts-legend-text{
  color:#fff!important;
}
/deep/ .apexcharts-title-text {
  fill: #fff!important;
 
}
</style>