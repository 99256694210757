<template>
  <b-modal id="join" centered ref="modal" title-class="font-22"
           hide-footer hide-header @shown="create()" @hide="password=''">
<div class="card">
  <div class="card-body">
    <div class="row mx-0">
      <button  class="btn col-4 mx-auto  btn-outline-light" :class="{'active':option==0}"
               @click="option=0">{{$t('join.reel')}}<i class="far fa-hourglass ms-2"></i></button>
      <button  class="btn col-4 mx-auto btn-outline-light " :class="{'active':option==1}"
               @click="option=1" >{{$t('join.bonus')}}<i class="far fa-hourglass ms-2" ></i></button>
    </div>
      <div class="row mx-0">

        <div class="form-group mt-3 mb-0">
          <label class="pb-2">{{$t('join.password')}} :</label>
          <b-form-input type="text" placeholder="Password" v-model="password" :class="{ 'is-invalid': typesubmit && $v.password.$error }"></b-form-input>
          <div v-if="typesubmit && $v.password.$error" class="invalid-feedback">
            <span v-if="!$v.password.required"> {{$t('join.required')}}</span>
          </div>
        </div>
      </div>
  </div>
</div>
    <b-button class="m-0 py-2 text-center btn-save w-100" @click="handleSubmit">
      {{$t('join.title')}}
    </b-button>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import Vue from "vue";
export default {
  name: "JoinScenario",
  props:['id','lang'],
  data(){
    return{
      password:localStorage.getItem('password#'+this.id+'#'+this.lang),
      option:1,
      typesubmit:false
    }
  },
  validations: {
      password: {required},
  },
  methods: {
    handleSubmit() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.join();
    },
    create(){
      this.typesubmit=false;
      this.password = localStorage.getItem('password#'+this.id+'#'+this.lang);

    },
    join() {
      this.$http
          .post('senarios/'+this.id+'/lang/'+this.lang+'/join',{'password':this.password})
          .then(response => {
            console.log(response.data)
            this.$alertify.success("Success")
            localStorage.removeItem(this.id);
            let key_name = 'password#'+this.id+'#'+this.lang
            localStorage.setItem(key_name,this.password )
            Vue.prototype.$http.defaults.headers.common['Authorization'] = this.password
            this.$router.push({name:'Game',params:{id:this.id,time:this.option}})
          })
          .catch(error => {
            console.log(error.response.data)
            this.$alertify.error(error.response.data.error)
          })

    }
  }
}
</script>

<style scoped>
/deep/ .modal-body {
  padding: 0rem !important;
}

/deep/ .modal-content {
  background-color: #2a2b2f;
  border-radius: 20px !important;

}
/deep/.card{
  background-color: inherit!important;
  border: none!important;
}
.btn-save {
  border-radius: 0 0 20px 20px!important;
  position: relative;
  bottom: 0;
  font-size: 20px!important;
  background-color: rgba(20, 5, 5, 1)!important;
  color: #FFFFFF!important;
}

.active {
  text-align: center;
  background: #fff;

}

h6 {
  padding: 40px 0;
}
</style>