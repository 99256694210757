<template>
  <main>
    <section class="container text-center pt-5 pb-2">
      <div class="row mx-0 ">
        <button  class="btn reset ms-auto btn-light m-2" style="cursor: pointer" @click="ResetLevel"><i class="fas fa-undo me-2"></i> Reset</button>
      </div>
      <div class="row mx-0">
        <div class="col-xl-10 col-md-9 mx-auto">
        <h2 class="text-dark-red mb-4"> {{ $t('game.level') + ' ' + level }}</h2>
        <p class="text-light fs-5 mb-4" v-if="scenario_level.message">{{scenario_level.message}}</p>

          <youtube :video-id="scenario_level.video" :player-vars="{ rel: 0 }" ref="youtube" @ready="ready" @paused="stopLoop" @ended="EndLoop" v-if="scenario_level.video" player-width="100%"
                   class="ratio ratio-21x9 mx-auto " @playing="playing"></youtube>


        </div>
        <div class="col-xl-2 text-end col-md-3 mb-2 d-md-block d-none" v-if="scenario_level.image">
          <img :src="scenario_level.image"  class="image" width="90%"/>
        </div>
      </div>
    </section>
    <div class="row mx-0" v-if="!is_dead">
    <div class="col-xl-6 mx-auto mb-4 row text-center mt-4" v-if="level== 4 && indices.length && show_indices" >

          <p class="text-light fs-5 " >{{ $t('game.horloges') }} :  <button  class=" btn btn-light" @click="IndicesModal( $t('game.indice') + ' ' +1,indices[0])" >{{ $t('game.indice') + " " + 1}}</button>
            /  <button class=" btn btn-light" @click="IndicesModal( $t('game.indice') + ' ' +2,indices[1])" >{{ $t('game.indice') + " " +2 }}</button>
          </p>
          <p class="text-light fs-5 " >{{ $t('game.livres') }} :   <button  class=" btn btn-light" @click="IndicesModal( $t('game.indice') + ' ' +3,indices[2])" >{{ $t('game.indice') + " " + 3}}</button>
            /  <button class=" btn btn-light"  @click="IndicesModal( $t('game.indice') + ' ' +4,indices[3])" >{{ $t('game.indice') + " " +4 }}</button>
            /  <button class=" btn btn-light"  @click="IndicesModal( $t('game.indice') + ' ' +5,indices[4])" >{{ $t('game.indice') + " " +5 }}</button>
          </p>
          <p class="text-light fs-5 mb-4" >{{ $t('game.corres') }} : <button  class=" btn btn-light" @click="IndicesModal( $t('game.indice') + ' ' +6,indices[5])" >{{ $t('game.indice') + " " + 6 }}</button></p>
      </div>
    <div class="col-xl-8 row mx-auto mb-4 justify-content-center" v-if="level!= 4 && indices.length">

      <div class="col-xl-2 col-md-2 col-6   text-center" v-for="(item,index) in indices" :key="item.id">
        <button class=" btn btn-light mb-2" v-if="item.is_solution == 0 && show_indices " @click="IndicesModal( $t('game.indice') + ' ' +parseInt(index + 1),item)" >{{ $t('game.indice') + " " + parseInt(index + 1) }}</button>
		<button class="btn btn-light mb-2" v-else-if="show_continue" id="solution" @click="IndicesModal( 'Solution',item)">Solution</button>

<!--        <b-tooltip :target="'indice'+index" triggers="hover"  placement="bottom">-->
<!--          <p v-if="item.type=='text'">{{ item.content }}</p>-->
<!--          <audio controls v-else>-->
<!--            <source :src="item.content" type="audio/mpeg">-->
<!--            Your browser does not support the audio element.-->
<!--          </audio>-->
<!--        </b-tooltip>-->
      </div>
      <!-- <div class="col-xl-2 col-md-2 col-6 mb-2  mx-xl-2  text-center" v-if="typeof this.solution.id != 'undefined' && show_continue" >

      </div> -->
    </div>
    <section class="row mx-0 align-items-center justify-content-center">
            <div class="col-xl-12 my-2 text-center " v-if="is_continue && show_continue && this.$route.params.time.toString() != '0'">
        <button class="btn btn-light" @click="skipLevel">{{ $t('game.skip') }}</button>
      </div>
      <div class="col-xl-8 mx-auto border-bottom" v-for="data in cadenas" :key="data.id">
      <iframe id="frame"  :class="{'hidden':!data.view_first}"
              :src="$url+'cadenas/'+data.url_id" ref="frame" allowfullscreen width="100%" height="600"
              style="border:none!important;"></iframe>
      </div>


    </section>

    </div>

      <set-time v-if="!is_dead & cadenas.length ==0"/>


       <indice :title="title" :indice_p="indice"/>
  </main>
</template>


<script>

import Vue from "vue";
import Indice from "@/components/indice";
import SetTime from "@/components/SetTime";

export default {
  name: "Game",
  components: {SetTime, Indice},
  props: ['lang'],
  data() {
    return {
      player: this.$refs.youtube,
      show_indices: false,
      is_played: false,
      show_continue: false,
      progress: 0,
      is_continue: 0,
      scenario_level:{
        video:'',
        message:''
      },
      processId: [],
      cadenas: [],
      cadenas_solved:[],
      indices: [],
      solves: 0,
      title:'',
      indice:{},
      code: localStorage.getItem(this.$route.params.id) || '*',
      is_dead:true

    }
  },
  watch: {
    lang: function () {
      this.getVideo()
      this.getIndices()
      this.$forceUpdate();

    },
  },
  computed: {
    level: function () {
      if (this.code == '*') return 1
      else return this.code.length + 1

    },

  },
  beforeCreate: function() {
    document.body.className = 'home';
  },
  created() {
    let lang = localStorage.getItem('lang')
    if(lang && this.$route.params.id) {
      const token = localStorage.getItem('password#' + this.$route.params.id + '#' + lang)
      if (token) {
        Vue.prototype.$http.defaults.headers.common['Authorization'] = token
      }
    }
    if (!localStorage.getItem(this.$route.params.id)) localStorage.setItem(this.$route.params.id, '*')
    this.getVideo()
    this.getCadenas()
    this.getIndices()

  },
  mounted() {
    window.addEventListener('message', e => {
        if(String(e.data).includes("submitted")) 
        {
          let cadena_id = e.data.split('-')[1];
          if(!this.cadenas_solved.includes(cadena_id))
          {
            this.cadenas_solved.push(cadena_id)
            this.solves+=1;
          }
        }
        if (e.data == "ImageClicked") {
            if (this.cadenas.length == this.solves ) {
              if (this.code == '*') this.code = '1'
              else this.code += '1'
              localStorage.setItem(this.$route.params.id, this.code)
              this.level = parseInt(this.level) + 1
              this.resetParams()
          }

        }
      
    }, false);
  },
  methods: {
    ResetLevel(){
      localStorage.setItem(this.$route.params.id,'*')
      this.code='*'
     this.resetParams()
    },

    skipLevel() {
      if (this.code == '*') this.code = '0'
      else this.code += '0'
      localStorage.setItem(this.$route.params.id, this.code)
      this.level = parseInt(this.level) + 1
      this.resetParams()
    },
resetParams(){
      this.stopLoop();
      this.progress = 0,
      window.scrollTo(0,0);
      this.solves = 0
      this.is_played = false,
      this.is_continue = 0,
      this.is_dead = true;
      this.getVideo()
      this.getCadenas()
      this.getIndices()
      this.$forceUpdate();
      this.show_continue = false
      this.show_indices = false


},
    ready(event) {
      console.log("ready", event)
      this.player = event.target
      this.show_indices = false;
      this.show_continue = false;
    },
    IndicesModal(title,indice){
      this.title= title
      this.indice= indice
      this.$root.$emit('bv::show::modal', 'indice')
    },
    stopLoop(){
      console.log("stop loop")
      this.processId.forEach(function(id) {
        clearInterval(id)
      })
    },
	EndLoop(){
		this.stopLoop()
		if (this.$route.params.time.toString() === '0' && !this.is_dead) 
          {
            this.skipLevel()
          }
	},
    playing() {
      let totalTime = this.player.getDuration();
      console.log(totalTime)
      let processId_tmp = setInterval(() => {
        let time = this.player.getCurrentTime()
        let progress_tmp = (time / totalTime) * 100;
        console.log("Playing : " + totalTime+" - "+ time + " - "+progress_tmp)
        this.progress = progress_tmp <= 100 ? progress_tmp : 100;
        if (this.progress >= 50) {
          this.show_indices = true
        }
        if (this.progress >= 90 ) {
          this.show_continue = true
        }

      }, 100);
      this.processId.push(processId_tmp)
      // The player is playing a video.
    },

    getIndices() {
      this.$http
          .get('senarios/' + this.$route.params.id + '/levels/' + this.level + '/modes/' + this.$route.params.time + '/indices/' + this.lang)
          .then(response => {
            this.indices = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    getVideo() {
      this.$http
          .get('senarios/' + this.$route.params.id + '/codes/' + this.code + '/video/' + this.lang)
          .then(response => {
            this.scenario_level = response.data
            this.is_continue = response.data.is_continue
            this.is_dead = response.data.is_dead

          })
          .catch(error => {
            console.log(error)
          })
    },
    getCadenas() {
      this.$http
          .get('senarios/' + this.$route.params.id + '/levels/' + this.level+'/lang/'+this.lang)
          .then(response => {
            this.cadenas = response.data
          })
          .catch(error => {
            console.log(error)
          })

    },

  }
}
</script>

<style scoped>
.container {
  position: relative;
}

.container video {
  position: relative;
  z-index: 0;
}
.text-dark-red{
  color: #c9211e;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 16px;
}

button {
  width: fit-content !important;
}

/deep/ .tooltip-inner {
  padding: 12px;
  max-width: fit-content;
  min-width: 300px;
}

.ratio {
  width: 100%;
}

@media screen and (max-width: 1100px) {
.image{
  width: 60%;
}

  iframe {
    height: fit-content;
  }
}
.hidden{
  display: none;
}
.ytplayer {
  pointer-events: none;
}
@media screen and (max-width: 1200px) {
iframe{
    height: 600px;
  }

}
@media screen and (max-width: 800px) {
.tooltip {
  position: absolute!important;
  top: 0px!important;
  left: 0px!important;
  will-change: transform!important;
  transform: translate3d(42px, 495px, 0px) !important;

}
  .btn{
    width: 100% !important;
  }
.reset{
  width: fit-content!important;
}

}
input[type=time]::-webkit-datetime-edit-ampm-field {
  display: none!important;
}
</style>