<template>
  <main>
    <senarios :lang="lang"/>
    <regles v-if="load" :rules="rules"/>
  </main>
</template>

<script>
import Regles from "@/components/Regles";
import Senarios from "@/components/Senarios";
export default {
  name: "Home",
  components: {Senarios, Regles},
  props:['lang'],
  beforeCreate: function() {
    document.body.className = 'home';
  },
  data(){
    return{
      load:false,
      video:'',
      rules:'',
    }
  },
  watch:{
    lang: function () {
      this.getInfos()

    },
  },
  created(){
    this.getInfos()
  },
  methods:{
    getInfos(){
      this.$http
          .get('infos/'+this.lang)
          .then(response => {
            response.data.forEach( (element) => {

              if(element.type == 'video') this.video = element.content
              if(element.type == 'rules') this.rules = element.content

            })
            this.load= true
            this.$forceUpdate();
          })
          .catch(error => {
            console.log(error)
          })
    }

  }
}
</script>

<style scoped>

</style>